.Connect-content {
    color: white;
    width: 70%;
}

img[alt=LinkedIn] {
    width: 200px;
}

.Connect-logos {
    display: inline-flex;

}

.Connect-logos * {
    margin-right: 20px;
}

.Connect-logo {
    padding: 4px;
}

.libutton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    text-align: center;
    outline: none;
    text-decoration: none !important;
    color: #ccc !important;
    height: 32px;
    border-radius: 16px;
    background-color: #0A66C2;
    font-family: "SF Pro Text", Helvetica, sans-serif;
    font-weight: bold;
  }
  