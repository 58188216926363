.Projects-content {
    color: black;
    width: 70%;
    min-width: 400px;
    margin: auto;
    font-size: calc(8px + 2vmin);
    text-shadow: 1px 1px 2px white;
}

#resume-pages {
    width: 100%;
    @media screen and (max-width: 768px) {
        display: none;
    }
}

#resume-link {
    @media screen and (min-width: 767px) {
        display: block;
    }
}

iframe {
    width: 49%;
}